 
import { Vue, Component, Watch } from "vue-property-decorator";
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { Usuario } from "@/core/models/cadastros";
import { ContratoService } from "@/core/services/cadastros";

@Component
export default class ListaUsuario extends mixins(Vue, ListPage) {
  item = new Usuario();
  service = new ContratoService();

  sheet:boolean = false;
  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;

  localBuscaRapida: string = "";
  titulo: string = 'Cofres';
  subTitulo: string = 'Lista dos cadastros de cofres para uso no sistema';
  temBuscaRapida: boolean = true;
  icone: string = 'mdi-cash';  

  options: any = {
    itemsPerPage: 15 
  };

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Nome do Cofre", value: "name-safe" },
    { text: "Id do Cofre", value: "uuid-safe" },
  ];
  
  @Watch("options", { deep: true })
  Atualizar() {
    this.loading = true;
    this.service.CofresD4Sign().then(
      res => {
        this.lista = res.data;
        this.totalLista = res.data.count;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })
    .finally(() => (this.loading = false));
  } 

  mounted(){ 
    
  }
}
